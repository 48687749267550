<template>
  <div class="retouren">
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-row>
        <v-col>
          <v-card v-if="selected_customer_info" class="">
            <v-card-title v-if="selected_customer_info"
              >{{ selected_customer_info.customer.name }} retour uploaden
            </v-card-title>
            <v-list-item three-line>
              <v-list-item-content>
                <v-progress-circular
                  v-if="uploading"
                  :rotate="360"
                  :size="100"
                  :width="15"
                  :value="uploadPercentage"
                  color="teal"
                >
                  {{ uploadPercentage }} %
                </v-progress-circular>

                <v-form v-if="!uploading">
                  <v-select
                    v-model="upload_sponsor"
                    :items="selected_customer_info.sponsors"
                    item-value="id"
                    item-text="name"
                    :error-messages="upload_sponsorErrors"
                    label="Campagne"
                    :required="true"
                    @change="$v.upload_sponsor.$touch()"
                    @blur="$v.upload_sponsor.$touch()"
                  >
                  </v-select>
                  <v-file-input
                    v-model="upload_file"
                    :required="true"
                    label="Retour CSV"
                    :error-messages="upload_fileErrors"
                    show-size
                  ></v-file-input>
                  <v-btn class="mr-4" @click="submit">
                    Retourbestand uploaden
                  </v-btn>
                </v-form>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CustomerPage from "@/mixins/customerpage";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "RetourUpload",
  mixins: [validationMixin, CustomerPage],
  data() {
    return {
      sponsor_retouren: null,
      upload_sponsor: null,
      upload_file: null,
      uploading: false,
      uploadPercentage: 0,
    };
  },
  validations() {
    return {
      upload_sponsor: {
        required,
      },
      upload_file: {
        required,
      },
    };
  },
  computed: {
    upload_sponsorErrors() {
      const errors = [];
      if (!this.$v.upload_sponsor.$error) return errors;
      errors.push("Kies campagne");
      return errors;
    },
    upload_fileErrors() {
      const errors = [];
      if (!this.$v.upload_file.$error) return errors;
      errors.push("Kies CSV bestand voor uoload");
      return errors;
    },
    breadcrumbs() {
      let b = this.defaultBreadcrumbs.slice();

      if (this.last_selected_customer_id) {
        b.push({
          text: "Retouren",
          exact: true,
          to: {
            name: "retouren",
            params: {
              customerId: this.last_selected_customer_id,
            },
          },
        });
      }
      b.push({
        disabled: true,
        text: "Retour file uploaden",
      });

      return b;
    },
  },
  mounted() {
    // load exports
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$error) {
        this.uploading = true;
        let formData = new FormData();
        formData.append("sponsor", this.upload_sponsor);
        formData.append("file", this.upload_file);

        this.axios
          .post("customer/" + this.customerId + "/returnfile", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 75)
              );
            }.bind(this),
            onDownloadProgress: function (progressEvent) {
              this.uploadPercentage =
                75 +
                parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 25)
                );
            }.bind(this),
          })
          .then(() => {
            this.uploading = false;

            this.$router.push({
              name: "retouren",
              params: {
                customerId: this.last_selected_customer_id,
              },
            });
          })
          .catch((error) => {
            if (error.response) {
              this.uploading = false;
              alert(error.response.data.error);
            }
          });
      }
    },
  },
};
</script>
